.login-mt{
    margin-top: 9% !important;
}

.login-head{
    margin-top: 8% !important;
}

.editprof-save-btn{
    margin-right:30px;
}

.txt-theme-color{
    color: #005691 !important;
}

.bg-theme-color{
    background-color: #005691 !important;
    color:#fff !important;
}

.logo-sidebar-name{
    color:#000;
    font-size: 20px;
}
//radio button right alignment
// .rbm{
//     margin-right:6rem;
// }
//css for width of search and dropdown of Account page
.search-w{
    width: 105%;
}
.dropdown-w{
    width: 108%;
}

.radio-btn-dashboard{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.radio-btn-p{
    padding-right: 10px;
}

.radioLabel{
    font-weight: normal !important
}

.client{
    display: inline-block;
    flex-direction: column;
    margin: auto 10px;
}

.arrow-dropdown{
    border: none !important;
    background-color: transparent !important;
    color: #000 !important;
}

.pl-0{
    padding-left: 0%;
}

.pr-0{
    padding-right:0%;
}

.sw > label{
    width:100%
}

.wizard .steps .number{
    color: #005691 !important;
    border-color: #005691 !important;
}

.wizard .steps .current a .number{
    color: #fff !important;
    background-color: #005691 !important;
    border-color: #005691 !important;
}

.arrow-spacing{
    margin-right:10px !important;
}

.modal-footer{
    padding-right: 0 !important;
}

.sidebar-link:hover{
    background-color: #005691 !important;
}

.sidebar-link span{
    color: #fff !important;
}

.sidebar-link i{
    color: #fff !important;
}

.sidebar-link.active span{
    text-decoration: underline !important;
}

.avatar-title{
    font-size: 24px !important;
    text-transform: uppercase !important;
}

.react-bs-table-no-data{
    text-align: center !important;
}

.table-height{
    min-height: 250px !important;
}

.table-max-height{
    max-height: 400px !important;
}

.table-data-margin{
    margin-top: 12px;
}
.ct-label{
    fill: rgb(0,0,0) !important;
}
.flatpickr-monthSelect-month.selected{
    background-color: #005691;
}

.border-theme-color{
    border: 1px solid #005691 !important;
}

select.arrows {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - .5rem), 100% 0;
    background-size:  0.7em 0.7em;
    background-repeat: no-repeat;
}

.image-area {
    position: relative;
    width: 100px;
    height: 100px;
    background: #333;
}

.image-area img{
    width: 100px;
    height: 100px;
}

.remove-image {
    position: absolute;
    color: red;
    font-size: 20px;
    top: -20px;
    right: -20px;
    cursor: pointer;
}

.avatar-title{
    font-size: 24px !important;
    text-transform: uppercase !important;
}

.btn-lbusers{
    background-color: #005691 !important;
    color:#fff !important;
}

.text-lbusers{
    color: #005691 !important;
}

.row-grey{
    background: rgb(238, 237, 237);
}

.table > :not(caption) > * > * {
    background-color: transparent !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background-color: transparent !important;
}